import type { NuxtConfig } from 'nuxt/schema'
import tailwindConfig from '../tailwind.config'

export const rokkaConfig: Exclude<NuxtConfig['rokka'], undefined> = {
  host: process.env.ROKKA_HOST || 'oris-local.rokka.io',
  viewports: {
    sm: 0,
    ...Object.fromEntries(
      Object.entries(tailwindConfig.theme.screens).map(([key, value]) => [
        key,
        parseInt(value),
      ]),
    ),
  },
  dpr: ['', '1.5', '2'],
  stacks: {
    crop: 'fe_vuepal_crop_copy',
    noCrop: 'fe_vuepal_no_crop_copy',
  },
}
